import { Table, Skeleton, Button, Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import MaterialIcon from "material-icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkDeletePermission, checkEditPermission } from "../../utils";
import AddPanelMembersModal from "./modal/AddPanelMembersModal";
import {
  cleanMemberDetails,
  deletePanel,
  deletePanelist,
  fetchPanels,
  setPanelInfoRedux,
} from "../../features/panel/panelSlice";
import DeleteModal from "../../components/DeleteModal";
import toast from "react-hot-toast";

function PanelTable({ handleOpenPanel }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { panelDetails, paneloading, panelActionLoading } = useSelector(
    (state) => state.panel
  );
  const { menuSidebarData } = useSelector((state) => state.admin);

  const [searchText, setSearchText] = useState("");

  const [open, setOpen] = useState(false);
  const [prodd, setProdd] = useState("");
  const [panel, setPanel] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenPanelMembers = () => {
    cleanPanelistName();
    setIsModalOpen(true);
    setProdd();
    setPanel();
  };

  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const showModalDelete = async () => {
    setIsModalOpenDelete(true);
  };

  const handleDelete = async () => {
    const res = await dispatch(
      deletePanelist({
        panId: prodd?.panId,
      })
    );
    if (res?.payload?.success) {
      toast.success(res.payload?.messages?.message);
      fetchPanelDetailsData();
      setIsModalOpenDelete(false);
    } else {
      toast.error(res.payload?.messages?.message);
      setIsModalOpenDelete(false);
    }
    setIsModalOpenDelete(false);
  };

  const content = `Are you sure you want to remove ${prodd?.pmeName} from the panel`;

  const [isModalOpenDeletePanel, setIsModalOpenDeletePanel] = useState(false);
  const showModalDeletePanel = async () => {
    setIsModalOpenDeletePanel(true);
  };

  const handleDeletePanel = async () => {
    const res = await dispatch(
      deletePanel({
        paId: panel?.paId,
      })
    );
    if (res?.payload?.success) {
      toast.success(res.payload?.messages?.message);
      fetchPanelDetailsData();
      setIsModalOpenDeletePanel(false);
    } else {
      toast.error(res.payload?.messages?.message);
      setIsModalOpenDeletePanel(false);
    }
  };

  const contentPanel = `Are you sure you want to remove panel ${panel?.paName}`;

  const columns = [
    {
      title: "Member Name",
      dataIndex: "pmeName",
      render: (text) => text,
    },
    {
      title: "Member Roles",
      dataIndex: "panRole",
      render: (text) => text,
    },
    {
      title: "Email",
      dataIndex: "pmeEmail",
      render: (text) => text,
    },
    {
      title: "Phone Number",
      dataIndex: "pmePhone",
      render: (text) => text,
    },
    {
      title: "Action",
      width: "150px",
      render: (item) => {
        return (
          <div className="flex items-center gap-x-5">
            {checkEditPermission(menuSidebarData, "ADMIN") && (
              <div onClick={() => setProdd(item)}>
                <button
                  className="flex items-center justify-center gap-x-1"
                  type="submit"
                  onClick={handleOpenPanelMembers}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 20.0002H21M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z"
                      stroke="#101828"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            )}
            {checkDeletePermission(menuSidebarData, "ADMIN") && (
              <div onClick={() => setProdd(item)}>
                <button
                  className="flex items-center justify-center gap-x-1"
                  type="submit"
                  onClick={showModalDelete}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M2.40039 5.39688H21.6004M8.40039 1.79688H15.6004M9.60039 17.3969V10.1969M14.4004 17.3969V10.1969M16.2004 22.1969H7.80039C6.47491 22.1969 5.40039 21.1224 5.40039 19.7969L4.85247 6.64683C4.82407 5.96508 5.36909 5.39688 6.05143 5.39688H17.9493C18.6317 5.39688 19.1767 5.96508 19.1483 6.64683L18.6004 19.7969C18.6004 21.1224 17.5259 22.1969 16.2004 22.1969Z"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  async function fetchPanelDetailsData() {
    await dispatch(
      fetchPanels({
        paInstId: user?.usrInstId,
      })
    );
  }

  const filteredData = searchText
    ? panelDetails?.filter((item) =>
        item?.paName?.toLowerCase().includes(searchText.toLowerCase())
      )
    : panelDetails;

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  async function cleanPanelistName() {
    await dispatch(cleanMemberDetails());
  }

  // useEffect(() => {
  //   fetchPanelDetailsData();
  // }, []);

  useEffect(() => {
    cleanPanelistName();
  }, []);

  useEffect(() => {
    setProdd();
    setPanel();
  }, []);

  return (
    <div className="px-10 py-[14px]">
      <div className="flex justify-between items-center  mt-[1.5rem]">
        <h3 className="font-[700] text-[24px] text-black1 dash-title">
          List of Panels
        </h3>

        <div className="flex justify-end">
          {checkEditPermission(menuSidebarData, "ADMIN") && (
            <button onClick={handleOpenPanel} className="w-[230px] save-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M19.5 11H13.5V5C13.5 4.73478 13.3946 4.48043 13.2071 4.29289C13.0196 4.10536 12.7652 4 12.5 4C12.2348 4 11.9804 4.10536 11.7929 4.29289C11.6054 4.48043 11.5 4.73478 11.5 5V11H5.5C5.23478 11 4.98043 11.1054 4.79289 11.2929C4.60536 11.4804 4.5 11.7348 4.5 12C4.5 12.2652 4.60536 12.5196 4.79289 12.7071C4.98043 12.8946 5.23478 13 5.5 13H11.5V19C11.5 19.2652 11.6054 19.5196 11.7929 19.7071C11.9804 19.8946 12.2348 20 12.5 20C12.7652 20 13.0196 19.8946 13.2071 19.7071C13.3946 19.5196 13.5 19.2652 13.5 19V13H19.5C19.7652 13 20.0196 12.8946 20.2071 12.7071C20.3946 12.5196 20.5 12.2652 20.5 12C20.5 11.7348 20.3946 11.4804 20.2071 11.2929C20.0196 11.1054 19.7652 11 19.5 11Z"
                  fill="#EDF8FF"
                />
              </svg>
              Add Panel
            </button>
          )}
        </div>
      </div>

      {panelDetails?.map((panel) => (
        <Table
          key={panel.paId}
          rowSelection={true}
          size={"middle"}
          bordered={true}
          pagination={{
            position: ["bottomCenter"],
            hideOnSinglePage: true,
          }}
          rowKey={panel?.paId}
          columns={tableColumns}
          dataSource={panel.children || []}
          scroll={"scroll"}
          className="custom-table mt-[2rem]"
          loading={paneloading}
          title={(record) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>{panel?.paName}</span>
                <div className="flex items-center justify-end gap-x-5">
                  {checkEditPermission(menuSidebarData, "ADMIN") && (
                    <button
                      className="flex items-center justify-center gap-x-1"
                      type="submit"
                      onClick={() => dispatch(setPanelInfoRedux(panel))}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        onClick={handleOpenPanel}
                      >
                        <path
                          d="M12 20.0002H21M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z"
                          stroke="#101828"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  )}
                  {checkDeletePermission(menuSidebarData, "ADMIN") && (
                    <button onClick={() => setPanel(panel)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        onClick={showModalDeletePanel}
                      >
                        <path
                          d="M2.40039 5.39688H21.6004M8.40039 1.79688H15.6004M9.60039 17.3969V10.1969M14.4004 17.3969V10.1969M16.2004 22.1969H7.80039C6.47491 22.1969 5.40039 21.1224 5.40039 19.7969L4.85247 6.64683C4.82407 5.96508 5.36909 5.39688 6.05143 5.39688H17.9493C18.6317 5.39688 19.1767 5.96508 19.1483 6.64683L18.6004 19.7969C18.6004 21.1224 17.5259 22.1969 16.2004 22.1969Z"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  )}

                  {checkEditPermission(menuSidebarData, "ADMIN") && (
                    <button
                      className="w-[250px] save-btn"
                      onClick={() => setPanel(panel)}
                    >
                      <span onClick={handleOpenPanelMembers}>
                        Add Panel Members
                      </span>
                    </button>
                  )}
                </div>
              </div>
            );
          }}
        />
      ))}

      <AddPanelMembersModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        prodd={prodd}
        panelId={panel?.paId}
      />
      <DeleteModal
        isModalOpen={isModalOpenDelete}
        setIsModalOpen={setIsModalOpenDelete}
        handleDelete={handleDelete}
        content={content}
        title="Remove Panelist"
        loading={panelActionLoading}
      />
      <DeleteModal
        isModalOpen={isModalOpenDeletePanel}
        setIsModalOpen={setIsModalOpenDeletePanel}
        handleDelete={handleDeletePanel}
        content={contentPanel}
        title="Remove Panel"
        loading={panelActionLoading}
      />
    </div>
  );
}

export default PanelTable;
