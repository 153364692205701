import React, { useEffect, useState } from "react";
import Header from "./Header";
import singlePanel from "../../assets/Single-Man-Circle_1--Streamline-Ultimate.svg.svg";
import { Breadcrumb, Button } from "antd";
import AddPanelModal from "./modal/AddPanelModal";
import PanelTable from "./PanelTable";
import {
  cleanPanelInfoRedux,
  fetchPanels,
} from "../../features/panel/panelSlice";
import { useDispatch, useSelector } from "react-redux";
import InsideHeader from "../../components/InsideHeader";
import { useNavigate } from "react-router-dom";
import { checkEditPermission } from "../../utils";
import toast from "react-hot-toast";

function PanelList() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { panelDetails, paneloading, panelActionLoading } = useSelector(
    (state) => state.panel
  );
  const { user } = useSelector((state) => state.auth);
  const { menuSidebarData } = useSelector((state) => state.admin);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOpenPanel = async () => {
    if (!checkEditPermission(menuSidebarData,"ADMIN")) {
      toast.error("You dont have right to perform this action");
      return
    } 
    cleanPanelData();
    setIsModalOpen(true);
  };
  async function cleanPanelData() {
    await dispatch(cleanPanelInfoRedux());
  }

  async function fetchPanelDetailsData() {
    await dispatch(
      fetchPanels({
        paInstId: user?.usrInstId,
      })
    );
  }

  useEffect(() => {
    cleanPanelData();
  }, []);

  useEffect(() => {
    fetchPanelDetailsData();
  }, []);

  return (
    <>
      <InsideHeader
        title="List of panelists"
        subtitle="Manage all panelists here"
        back={false}
      />

      <div className="mt-[2.5rem] px-10 font-dmSans text-[18px]">
        <Breadcrumb
          items={[
            {
              title: (
                <span
                  className="font-dmSans cursor-pointer "
                  onClick={() => navigate(-1)}
                >
                  Administrator
                </span>
              ),
            },
            {
              title: (
                <span className="text-blueDark font-dmSans">Panelist list</span>
              ),
            },
          ]}
        />
      </div>
      <div className="bg-white mr-3 mb-[10px]">
        {panelDetails && panelDetails?.length > 0 ? (
          <PanelTable handleOpenPanel={handleOpenPanel} />
        ) : (
          <div className="items-center flex text-center h-[648px] justify-center flex-col">
            <img src={singlePanel} alt="singlepanel" />

            <div className="text-center text-[#9D9797] text-[22px] font-normal font-dmSans leading-[33px]">
              Create a panel that will conduct interviews under this Job
              Position. <br />
              You can create multiple panels.
            </div>

            <button
              className={`w-[250px] save-btn mt-5 ${!checkEditPermission(menuSidebarData,"ADMIN")? 'cursor-not-allowed' : 'cursor-pointer'}`}
              onClick={handleOpenPanel}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M19.5 11H13.5V5C13.5 4.73478 13.3946 4.48043 13.2071 4.29289C13.0196 4.10536 12.7652 4 12.5 4C12.2348 4 11.9804 4.10536 11.7929 4.29289C11.6054 4.48043 11.5 4.73478 11.5 5V11H5.5C5.23478 11 4.98043 11.1054 4.79289 11.2929C4.60536 11.4804 4.5 11.7348 4.5 12C4.5 12.2652 4.60536 12.5196 4.79289 12.7071C4.98043 12.8946 5.23478 13 5.5 13H11.5V19C11.5 19.2652 11.6054 19.5196 11.7929 19.7071C11.9804 19.8946 12.2348 20 12.5 20C12.7652 20 13.0196 19.8946 13.2071 19.7071C13.3946 19.5196 13.5 19.2652 13.5 19V13H19.5C19.7652 13 20.0196 12.8946 20.2071 12.7071C20.3946 12.5196 20.5 12.2652 20.5 12C20.5 11.7348 20.3946 11.4804 20.2071 11.2929C20.0196 11.1054 19.7652 11 19.5 11Z"
                  fill="#EDF8FF"
                />
              </svg>
              Add Panel
            </button>
          </div>
        )}
      </div>
      <AddPanelModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
}

export default PanelList;
